<template>
    <el-link icon="el-icon-back" href='../roles' type='primary'>回到列表</el-link>
    <div class="role-content">
        <el-row>
            <el-col :span="6">
                <span class='label'>ID:</span>
                <span class='display'>{{role.id}}</span>
            </el-col>
            <el-col :span="6">
                <span class='label'>角色编码:</span>
                <span class='display'>{{role.roleCode}}</span>
            </el-col>
            <el-col :span="6">
                <span class='label'>角色名:</span>
                <span class='display'>{{role.roleName}}</span>
            </el-col>
            <el-col :span="6">
                <span class='label'>角色描述:</span>
                <span class='display'>{{role.roleDesc}}</span>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6">
                <span class='label'>创建人:</span>
                <span class='display'>{{role.base.creatorName}}</span>
            </el-col>
            <el-col :span="6">
                <span class='label'>创建时间:</span>
                <span class='display'>{{role.base.created}}</span>
            </el-col>
            <el-col :span="6">
                <span class='label'>上次更新:</span>
                <span class='display'>{{role.base.updaterName}}</span>
            </el-col>
            <el-col :span="6">
                <span class='label'>上次更新时间:</span>
                <span class='display'>{{role.base.updated}}</span>
            </el-col>
        </el-row>
    </div>

    <el-tabs tab-position="top" style="height: 200px;">
        <el-tab-pane label="用户管理">
            <el-button type="primary" circle icon='el-icon-plus' @click="visible = true"></el-button>
            <OrgTree :visible='visible' :selected='memberhads' @confirm='addMember' @close='visible = false'></OrgTree>
            <el-table :data="memberData" stripe border highlight-current-row style="width: 100%">
                <el-table-column prop="userId" label="用户ID" width="180"></el-table-column>
                <el-table-column prop="name" label="姓名" width="120"></el-table-column>
                <el-table-column prop="memberType" label="类型" width="80"></el-table-column>
                <el-table-column prop="base.created" width="180" label="创建时间"></el-table-column>
                <el-table-column prop="base.creatorName" width="120" label="创建人"></el-table-column>
                <el-table-column prop="base.updated" width="180" label="更新时间"></el-table-column>
                <el-table-column prop="base.updaterName" width="120" label="更新人"></el-table-column>
                <el-table-column prop="base.updaterName" label="操作">
                    <template #default="scope">
                        <el-popconfirm title='确认删除？' confirmButtonText='删除' cancelButtonText='取消'
                            @confirm='removeMember(scope.row)'>
                            <template #reference>
                                <el-button size='mini' icon="el-icon-circle-close"></el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-tab-pane>
        <el-tab-pane label="配置管理" style="overflow:auto">
            <el-button type="primary" icon="el-icon-check" circle @click="getCheckedNodes"></el-button>
            <el-tree show-checkbox :data="treeData" ref="rstree" node-key="id" default-expand-all>
            </el-tree>
        </el-tab-pane>
    </el-tabs>
</template>
<style>
    .role-content {
        border-radius: 4px;
        border: 1px solid #ebeef5;
        background-color: #fff;
        overflow: hidden;
        color: #303133;
        transition: .3s;
        padding: 10px;
    }

    .role-content .el-row {
        padding: 3px 0;
    }

    .role-content .el-row .label {
        font-weight: 400;
        font-size: 14px;
        padding-right: 4px;
    }

    .role-content .el-row .display {
        font-weight: 300;
        font-size: 13px;
    }
</style>
<script>
    import router from '@/router/index.js'
    import { getPathVariableAppKey, getPathVariable } from '@/utils/tools'
    import { roleGet, resourceTree, bindList, bindSave, memberList, memberSave, memberRemove } from '@/utils/api'
    import { ref, reactive, provide } from 'vue'
    import OrgTree from '@/components/OrgTree'


    export default {
        components: { OrgTree },
        setup(props, context) {
            const appkey = getPathVariableAppKey()
            let roleId = getPathVariable('roleId')

            let role = ref({
                id: 0,
                roleCode: "",
                roleDesc: "",
                roleName: "",
                base: {
                    created: "",
                    creatorName: "",
                    updated: "",
                    updaterName: "",
                }
            })
            let treeData = ref([])
            let memberData = ref([])
            let memberhads = ref([])
            let visible = ref(false)

            // 获取角色详情
            function getRoleDetail() {
                if (roleId) {
                    roleGet(roleId).then(res => {
                        role.value = res.data
                    })
                } else {
                    router.push('../roles')
                }
            }
            //搜索资源树
            function searchResourceTree() {
                resourceTree(appkey).then(res => {
                    treeData.value = [res.data]
                })
            }
            //搜索成员列表
            function searchMember() {
                memberList(roleId).then(res => {
                    memberData.value = res.data
                    let meb = []
                    res.data.forEach(rd => {
                        meb.push({
                            id: rd.userId,
                            label: rd.name,
                            type: rd.memberType
                        })
                    })
                    memberhads.value = meb
                })
            }
            // 添加成员
            function addMember(list) {
                console.log(list.length)
                if (list && list.length > 0) {

                    let members = []
                    list.forEach(mi => {
                        members.push({
                            userId: mi.id,
                            name: mi.label,
                            memberType: mi.type === '员工' ? 1 : 2
                        })
                    })

                    memberSave(roleId, members).then(res => {
                        searchMember()
                    })
                }
                visible.value = false
            }
            // 移除成员
            function removeMember(row) {
                if (row) {
                    let request = {
                        ids: [row.id]
                    }
                    memberRemove(roleId, request).then(res => {
                        searchMember()
                    })
                }
            }
            return {
                appkey,
                roleId,
                role,
                treeData,
                visible,
                memberData,
                memberhads,

                getRoleDetail,
                searchResourceTree,
                searchMember,
                addMember,
                removeMember,
            }
        },
        mounted() {
            this.getRoleDetail()
            this.searchMember()
            this.searchResourceTree()
            this.flushBind()
        },
        methods: {
            // 保存绑定
            saveBinds(nodes) {
                let _this = this
                bindSave(this.roleId, nodes).then(res => {
                    //_this.flushBind()
                })
            },
            // 刷新绑定
            flushBind() {
                let _this = this
                bindList(this.roleId).then(res => {
                    let checked = []
                    res.data.forEach(bind => {
                        checked.push(bind.bindId)
                    })
                    _this.$refs.rstree.setCheckedKeys(checked)
                })
            },
            // 节点选择事件（保存绑定）
            getCheckedNodes() {
                let _this = this
                let bindAdded = []
                this.$refs.rstree.getCheckedNodes().forEach(node => {
                    bindAdded.push({
                        bindId: node.id,
                        bindType: 1
                    })
                })
                if (bindAdded.length > 0) {
                    _this.saveBinds(bindAdded)
                }
            },
        },
    }
</script>