<template>
    <el-dialog v-model='show' :before-close="close">
        <div class="transfer">
            <div class="transfer-list">
                <el-input size="small" placeholder="搜索" v-model="filterText"> </el-input>
                <el-tree :data="treeData" node-key="id" ref="orgTree" show-checkbox check-strictly
                    :default-expanded-keys="['1']" :expand-on-click-node='false' :filter-node-method="filterNode"
                    @check-change="checkedNode" :default-checked-keys="initlist">
                    <template #default="{ node }">
                        <span class="custom-tree-node">
                            <i v-if="node.data.info" size="small" style="padding-right: 3px;"
                                :class="node.data.info.type === '员工' ? 'el-icon-user' : 'el-icon-house'"></i>
                            <span>{{ node.label }}</span>
                        </span>
                    </template>
                </el-tree>
            </div>
            <div class="transfer-list">
                <ul class="checked-list">
                    <li v-for="sk in addlist" :key="sk.id">
                        <i class="el-icon-close close" @click='removeNode(sk)'></i>
                        <i :class="sk.type === '员工' ? 'el-icon-user' : 'el-icon-house'"></i>
                        <span>{{ sk.label }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </template>
    </el-dialog>
</template>
<style>
    .transfer {
        color: rgba(0, 0, 0, .65);
        display: flex;
    }

    .transfer .transfer-list {
        flex: auto;
        position: relative;
        display: inline-block;
        min-width: 180px;
        max-width: 360px;
        min-height: 200px;
        max-height: 360px;
        vertical-align: middle;
        border: 1px solid #ebedf0;
        border-radius: 4px;
        overflow: auto;
        margin-right: 12px;
    }

    .transfer .transfer-operation .btn {
        display: block;
        margin: 3px;
    }

    ul.checked-list {
        margin: 5px;
    }

    ul.checked-list li {
        list-style: outside none none;
        margin: 3px 0 0 -30px;
    }

    ul.checked-list .close {
        padding-right: 3px;
        cursor: pointer;
    }
</style>
<script>
    import { ref, watch, reactive } from 'vue'
    import { searchOrgTree } from '@/utils/api';
    export default {
        emits: ["confirm", "close"],
        props: ['visible', 'selected'],
        setup(props, { emit }) {
            let show = ref(false)
            let treeData = ref([])
            let filterText = ref('')
            let initlist = ref([])
            let addlist = ref([
                //{
                // id: 0,
                // label: '',
                // type: '部门',
                //}
            ])
            watch(() => [props.visible, props.selected], ([newv, newa], [oldv, olda]) => {
                show.value = newv
                addlist.value = newa
            })

            // 确认保存组件
            function confirm() {
                emit('confirm', addlist.value)
            }
            // 关闭组件
            function close() {
                emit('close')
            }
            // 选择节点checkbox
            function checkedNode(data, checked, indeterminate) {
                if (!data.info) {
                    return
                }
                // 查找是否已选择
                const index = addlist.value.findIndex(item => item.id === data.id);
                console.log(index)
                console.log(data)
                // 选中
                if (checked) {
                    // 未选择，添加
                    if (index === -1) {
                        addlist.value.push({
                            id: data.id,
                            label: data.label,
                            type: data.info.type,
                        })
                    }
                } else {
                    // 未选中
                    // 已选择
                    if (index > -1) {
                        addlist.value.splice(index, 1);
                    }
                }
            }

            return {
                show,
                treeData,
                filterText,
                addlist,
                initlist,

                confirm,
                close,

                checkedNode,
            }
        },
        watch: {
            filterText(val) {
                this.$refs.orgTree.filter(val);
            }
        },
        methods: {
            // 树过滤节点
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
            // 树 移除节点
            removeNode(data) {
                if (data) {
                    const index = this.addlist.findIndex(item => item.id === data.id);
                    if (index > -1) {
                        // 删除已选择列表
                        this.addlist.splice(index, 1);
                        // 查询已选择节点
                        let checkedNodeKeys = this.$refs.orgTree.getCheckedKeys();
                        const indexInTree = checkedNodeKeys.findIndex(item => item === data.id);
                        if (indexInTree > -1) {
                            // 删除已选择节点
                            checkedNodeKeys.splice(indexInTree, 1)
                            this.$refs.orgTree.setCheckedKeys(checkedNodeKeys);
                        }
                    }
                }
            },
            // 查询组织树
            searchTree() {
                let _this = this
                searchOrgTree().then(res => {
                    if (res.data) {
                        if (_this.addlist.length > 0) {
                            let checkedIds = []
                            _this.addlist.forEach(al => {
                                checkedIds.push(al.id + '')
                            });
                            _this.initlist = checkedIds
                        }
                        _this.treeData = [res.data]
                    }
                })
            },
        },
        mounted() {
            this.searchTree()
        }
    }
</script>